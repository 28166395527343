import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import { Footer } from '../components/footer'
import styled from 'styled-components'
import logo from '../components/img/IMG_0028.jpg'
import fpk from '../components/img/fpk2.png'

const LayoutCatalog = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: end;
  position: relative;
  width: 100%;
  max-width: 78rem;
  margin: 0 auto;

  h1 {
    font-family: 'GT Walsheim Pro Bold';
  }

  .about_wrapper {
    width: 100%;
    /* // max-width: 580px; */
    padding-left: 15px;
    /* background: rgb(242, 241, 226);
    border-radius: 5px;
    box-shadow: 15px 10px 40px -2px rgba(0,0,0,.8); */
  }

  ul {
    padding: 0;
    list-style-type: none;
  }

  .about_images {
    margin-bottom: 10vw;
  }
`

export default () => {
  return (
    <Layout location="/privacy-policy">
      <LayoutCatalog>
        <Helmet title="Согласие на обработку персональных данных" />
        <div className="about_wrapper">
          <h1>Согласие на обработку персональных данных</h1>
          <p>
            Даю согласие на обработку моих персональных данных в Крымском
            республиканском отделении Всероссийской общественной организации
            «Всероссийское общество охраны памятников истории и культуры» (далее
            – Организация), относящихся исключительно к перечисленным ниже
            категориям персональных данных: фамилия, имя, отчество; контактные
            данные. Я даю согласие на использование персональных данных
            исключительно в целях представления заявления об объекте, обладающем
            признаками объекта культурного наследия, для предоставления мне
            информации и материалов Организации о предлагаемом на выявление
            объекте, а также на хранение данных об этих результатах на
            электронных носителях. Настоящее согласие предоставляется мной на
            осуществление действий в отношении моих персональных данных, которые
            необходимы для достижения указанных выше целей, включая (без
            ограничения) сбор, систематизацию, накопление, хранение, уточнение
            (обновление, изменение), использование, передачу третьим лицам для
            осуществления действий по обмену информацией, обезличивание,
            блокирование персональных данных, а также осуществление любых иных
            действий, предусмотренных действующим законодательством Российской
            Федерации. Я проинформирован (а), что Организация гарантирует
            обработку моих персональных данных в соответствии с действующим
            законодательством Российской Федерации как неавтоматизированным, так
            и автоматизированным способами. Данное согласие действует до
            достижения целей обработки персональных данных или в течение срока
            хранения информации. Данное согласие может быть отозвано в любой
            момент по моему письменному заявлению. Я подтверждаю, что, давая
            такое согласие, я действую по собственной воле и в своих интересах.
          </p>
          <p>Контакт для связи: krovoopic@mail.ru</p>
        </div>
      </LayoutCatalog>
      <Footer />
    </Layout>
  )
}
